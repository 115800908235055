import React from 'react';

class EmailLink extends React.Component {
  state = { at: '(zavináč)', isMounted: false }

  componentDidMount() {
    this.setState({ at: '\u0040', isMounted: true });
  }

  render() {
    const { to, subject, className, children } = this.props;
    const { at, isMounted } = this.state;
    const href = subject
      ? `mailto:${to}${at}komsek.cz?subject=${subject}`
      : `mailto:${to}${at}komsek.cz`;

    return (
      <a href={isMounted ? href : ''} className={className}>
        {children ? children : `${to}${at}komsek.cz`}
      </a>
    );
  }
}

EmailLink.defaultProps = {
  className: '',
};


class PhoneLink extends React.Component {
  state = { i: <i>sedm set sedmdesát sedm</i>, isMounted: false }

  componentDidMount() {
    this.setState({ i: '777', isMounted: true });
  }

  render() {
    const { i, isMounted } = this.state;
    return (
      <a href={isMounted ? `tel:+420 474 ${i} 140` : ``}
          style={{color: 'inherit'}}>
        +420 474 {i} 140
      </a>
    );
  }
}

export { EmailLink, PhoneLink };
