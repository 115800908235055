import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

import { EmailLink, PhoneLink } from 'components/links';

const Header = ({ expanded }) => {
  return (
    <header className={expanded ? 'expanded' : ''}>
      {expanded && (
        <div className="container grid-lg">
          <div className="navbar">
            <div className="navbar-section">
              <h1>
                <span className="subtitle">Servisní organizace</span>
                KOMSEK
              </h1>
            </div>
            <div className="navbar-section">
              <span className="text-large text-nowrap">
                <FontAwesomeIcon icon={faEnvelope} size="sm" />
                &ensp;<EmailLink to={'info'} />
                <br/>
                <FontAwesomeIcon icon={faPhoneAlt} />
                &ensp;<PhoneLink />
              </span>
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="container grid-lg">
          <nav>
    	      <div className="navbar">
              {!expanded && (
                <div className="navbar-section">
                  <Link to="/" className="navbar-brand h3" title="Jít na úvodní stránku">
                    KOMSEK
                  </Link>
                </div>
              )}
              <div className="navbar-section tabs">
                <Link to="/o-nas/" activeClassName="active">O nás</Link>
                <Link to="/oblasti/" activeClassName="active">Oblasti</Link>
                <Link to="/aktuality/" activeClassName="active">Aktuality</Link>
                <Link to="/kariera/" activeClassName="active">Kariéra</Link>
                <Link to="/kontakty/" activeClassName="active">Kontakty</Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

Header.defaultProps = {
  expanded: false,
}

export default Header;
