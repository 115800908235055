import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmbulance, faEnvelopeSquare, faHospitalAlt, faPhoneSquareAlt, faLink
  } from '@fortawesome/free-solid-svg-icons';

import { EmailLink, PhoneLink } from 'components/links';

class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
  }

  onScroll = (event) => {
    this.forceUpdate();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  render() {
    // scroll: 0 ~ top ... 1 ~ bottom
    let scroll = 0;
    const element = this.ref.current;

    if (element) {
      const scrollYMax = document.body.scrollHeight - window.innerHeight;
      const activeHeight =
        element.offsetHeight < scrollYMax ? element.offsetHeight : scrollYMax;
      scroll = 1 + (window.scrollY - scrollYMax) / activeHeight;
      scroll = scroll > 0 ? (scroll < 1 ? scroll : 1) : 0;
    }

    return (
      <footer>
        <div className="decor container grid-xl">
          <p>Pomáháme Vám růst&hellip;</p>
          <FontAwesomeIcon icon={faAmbulance} style={{
            transform: `translateX(${(scroll - 0.5) * 100}%)`,
          }} />
          <FontAwesomeIcon icon={faHospitalAlt} style={{
            transform: `scale(${0.5 + scroll/2})`,
          }} />
        </div>
        <div className="dark footer" ref={this.ref}>
          <div className="container grid-lg">
            <div className="columns">
              <div className="column col-6 col-sm-12">
                <h3 style={{ fontSize: '2rem' }}>
                  <span className="subtitle">Servisní organizace</span>
                  <span className="text-primary">KOMSEK</span>
                </h3>
              </div>
              <div className="column col-6 col-sm-12">
                <h3>
                  Kontakt&nbsp;
                  <Link to="/kontakty/" aria-label="Stránka kontaktů">
                    <FontAwesomeIcon icon={faLink} size="sm" />
                  </Link>
                </h3>
                <p>
                  KOMSEK &ndash; servisní organizace<br />
                  <FontAwesomeIcon icon={faEnvelopeSquare} size="sm" />
                  &ensp;<EmailLink to="info" className="text-light" /><br />
                  <FontAwesomeIcon icon={faPhoneSquareAlt} size="sm" />
                  &ensp;<PhoneLink />
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }

}

export default Footer;
